export const products = [
  { 
    id: 1, 
    name: "White Oak", 
    price: 10000000, 
    description: "Features include a fully equipped gym with state-of-the-art fitness equipment, a safe and vibrant children’s play area designed for all ages, and an exclusive, 5-star hotel-grade lounge with luxurious furnishings and ambiance. Residents can enjoy premium amenities for relaxation, fitness, and family-friendly recreation, offering a truly upscale living experience.", 
    image: "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730886344/IMG-20241106-WA0003_kfxgxe.jpg",
    location: "Mehedibagh",
    beds: 2,
    baths: 1,
    area: 2961,
    isAvailable: true,
    moreImages: [
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730886343/IMG-20241106-WA0000_wjsj1q.jpg",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730886344/IMG-20241106-WA0001_vzdx0b.jpg",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730886344/IMG-20241106-WA0002_gh7smy.jpg",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730886345/IMG-20241106-WA0004_jrozyr.jpg",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730886345/IMG-20241106-WA0007_if7sk2.jpg",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730886345/IMG-20241106-WA0005_g3pvyg.jpg",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730886345/IMG-20241106-WA0008_blkltj.jpg",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730886345/IMG-20241106-WA0006_nuffif.jpg",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730886345/IMG-20241106-WA0009_acajml.jpg"
    ]
  },
  { 
    id: 2, 
    name: "Pine Estates", 
    price: 10000000, 
    description: "Located around an hour away from Paris, between the Perche and the Iton valley, in a beautiful wooded park bordered by a charming stream, this country property immediately seduces with its bucolic and soothing environment. An ideal choice for sports and leisure enthusiasts who will be able to take advantage of its swimming pool (11m x 5m), tennis court, gym and sauna.", 
    image: "https://images.pexels.com/photos/3843282/pexels-photo-3843282.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    location: "Uptown",
    beds: 3,
    baths: 2,
    area: 1400,
    isAvailable: false,
    moreImages: [
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png"
    ]
  },
  { 
    id: 3, 
    name: "Maple Valley", 
    price: 10000000, 
    description: "Located around an hour away from Paris, between the Perche and the Iton valley, in a beautiful wooded park bordered by a charming stream, this country property immediately seduces with its bucolic and soothing environment. An ideal choice for sports and leisure enthusiasts who will be able to take advantage of its swimming pool (11m x 5m), tennis court, gym and sauna.", 
    image: "https://images.pexels.com/photos/3843282/pexels-photo-3843282.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    location: "Suburban",
    beds: 4,
    baths: 3,
    area: 1600,
    isAvailable: false,
    moreImages: [
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png"
    ]
  },
  { 
    id: 4, 
    name: "Pine Estates", 
    price: 10000000, 
    description: "Located around an hour away from Paris, between the Perche and the Iton valley, in a beautiful wooded park bordered by a charming stream, this country property immediately seduces with its bucolic and soothing environment. An ideal choice for sports and leisure enthusiasts who will be able to take advantage of its swimming pool (11m x 5m), tennis court, gym and sauna.", 
    image: "https://images.pexels.com/photos/3843282/pexels-photo-3843282.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    location: "Uptown",
    beds: 3,
    baths: 2,
    area: 1400,
    isAvailable: false,
    moreImages: [
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png"
    ]
  },
  { 
    id: 5, 
    name: "Pine Estates", 
    price: 10000000, 
    description: "Located around an hour away from Paris, between the Perche and the Iton valley, in a beautiful wooded park bordered by a charming stream, this country property immediately seduces with its bucolic and soothing environment. An ideal choice for sports and leisure enthusiasts who will be able to take advantage of its swimming pool (11m x 5m), tennis court, gym and sauna.", 
    image: "https://images.pexels.com/photos/5499120/pexels-photo-5499120.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    location: "Uptown",
    beds: 3,
    baths: 2,
    area: 1400,
    isAvailable: false,
    moreImages: [
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png"
    ]
  },
  { 
    id: 6, 
    name: "Pine Estates", 
    price: 10000000, 
    description: "Located around an hour away from Paris, between the Perche and the Iton valley, in a beautiful wooded park bordered by a charming stream, this country property immediately seduces with its bucolic and soothing environment. An ideal choice for sports and leisure enthusiasts who will be able to take advantage of its swimming pool (11m x 5m), tennis court, gym and sauna.", 
    image: "https://images.pexels.com/photos/3843282/pexels-photo-3843282.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    location: "Uptown",
    beds: 3,
    baths: 2,
    area: 1400,
    isAvailable: false,
    moreImages: [
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png"
    ]
  },
  { 
    id: 7, 
    name: "Pine Estates", 
    price: 10000000, 
    description: "Located around an hour away from Paris, between the Perche and the Iton valley, in a beautiful wooded park bordered by a charming stream, this country property immediately seduces with its bucolic and soothing environment. An ideal choice for sports and leisure enthusiasts who will be able to take advantage of its swimming pool (11m x 5m), tennis court, gym and sauna.", 
    image: "https://images.pexels.com/photos/5499120/pexels-photo-5499120.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    location: "Uptown",
    beds: 3,
    baths: 2,
    area: 1400,
    isAvailable: false,
    moreImages: [
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png"
    ]
  },
  { 
    id: 8, 
    name: "Pine Estates", 
    price: 10000000, 
    description: "Located around an hour away from Paris, between the Perche and the Iton valley, in a beautiful wooded park bordered by a charming stream, this country property immediately seduces with its bucolic and soothing environment. An ideal choice for sports and leisure enthusiasts who will be able to take advantage of its swimming pool (11m x 5m), tennis court, gym and sauna.", 
    image: "https://images.pexels.com/photos/3843282/pexels-photo-3843282.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    location: "Uptown",
    beds: 3,
    baths: 2,
    area: 1400,
    isAvailable: false,
    moreImages: [
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
      "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png"
    ]
  },
];

export const realEstateProducts = [
  { id: 301, name: "Apartment", price: 10, description: "Gentle makeup remover for sensitive skin.", image: "https://images.pexels.com/photos/2285099/pexels-photo-2285099.jpeg?auto=compress&cs=tinysrgb&w=600" },
  { id: 302, name: "Villa", price: 10, image: "https://images.pexels.com/photos/2215166/pexels-photo-2215166.jpeg?auto=compress&cs=tinysrgb&w=600" },
  { id: 303, name: "Office Space", price: 10, image: "https://images.pexels.com/photos/276551/pexels-photo-276551.jpeg?auto=compress&cs=tinysrgb&w=600" },
];

export const sliderProducts = [
  { id: 401, name: "White Oak", price: 10, description: "Gentle makeup remover for sensitive skin.", 
    image: "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308977/1_64_o4a3yh.jpg" ,
  moreImages: [
    "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
  "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
  "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
  "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
  "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
  "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
  "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
  "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
  "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
  "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
  "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
  "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png",
  "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308991/one_jo8gx2.png"
  ] },
  { id: 402, name: "Apartment", price: 10, image: "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308975/1_60_vslfte.jpg" },
  { id: 403, name: "Apartment", price: 10, image: "https://res.cloudinary.com/dasqmi9fl/image/upload/v1730308976/1_62_cbxd2c.jpg" },
];